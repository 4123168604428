import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class ResNoticeSvc
 */
class ManageSvc extends ServiceExec {
  /**
   *  업제정보 상세
   *  @param Authorization {string} Authorization
   */
  fetchUserCompany(params = {}) {
    return this.get(`/api/manage/fetchUserCompany`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원 빌라현황 상세
   *  @param Authorization {string} Authorization
   *  @param type {integer} 타입 (1: 주차현황표, 2: 안내문, 3: 산출내역
   */
  fetchUserManage(params = {}) {
    return this.get(`/api/manage/fetchUserManage`, params).then(response => {
      return response.data
    })
  }
}
export default new ManageSvc()
