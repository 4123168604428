<template>
  <teleport to="body">
    <spinner></spinner>
  </teleport>
  <div class="wrap" id="wrap" style="height: 100%;">
    <!--  <div class="wrap" id="wrap">-->
    <div class="body" v-show="!isShowSideBar && $Util.isEmpty(modalComponents)">
      <router-view v-slot="{ Component }" v-if="allowPath">
        <header-bar /><!-- 상단 헤더바 -->
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
      <router-view v-slot="{ Component }" v-else>
        <header-bar /><!-- 상단 헤더바 -->
        <component :is="Component" />
      </router-view>
    </div>
    <nav-bar v-show="routeLayout.isNav" /><!-- 하단 네비게이션바 -->
    <!-- 전체화면 modal component -->
    <teleport to="#wrap" v-for="(item, index) in modalComponents" :key="index">
      <component
        :index="index"
        :is="item.component"
        v-bind="item"
        @close="fnCloseModal"
      />
    </teleport>
    <!-- alert modal component -->
    <teleport
      to="#wrap"
      v-for="(item, index) in modalAlertComponents"
      :key="index"
    >
      <component
        :index="index"
        :is="item.component"
        v-bind="item"
        @close="fnCloseModalAlert"
      />
    </teleport>
  </div>
</template>

<script>
import HeaderBar from '@/components/common/layouts/header-bar/index'
import NavBar from '@/components/common/layouts/nav-bar/index'
import Spinner from '@/components/common/layouts/spinner/index'
import { computed, watch, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const layoutState = () => {
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const state = reactive({
    isShowSideBar: computed(() => getters['layout/getIsShowSideBar']),
    routeLayout: computed(() => getters['layout/getRouteLayout']),
    modalAlertComponents: computed(
      () => getters['layout/getModalAlertComponents']
    ),
    modalComponents: computed(() => getters['layout/getModalComponents']),
    pushFullPath: computed(() => getters['user/getPushFullPath']),
    unWatch: '',
    allowPath: true
  })
  /** methods **/
  const fnCloseModalAlert = (index = 0) => {
    dispatch('layout/closeModalAlertComponent', { index })
  }
  const fnRemoveAllModalAlertComponent = () => {
    dispatch('layout/removeAllModalAlertComponent')
  }
  const fnCloseModal = (index = 0) => {
    dispatch('layout/closeModalComponent', { index })
  }
  const fnRemoveAllModalComponent = () => {
    dispatch('layout/removeAllModalComponent')
  }
  const fnCloseSideBar = () => {
    dispatch('layout/updateIsShowSideBar', {
      isShowSideBar: false
    })
  }
  /** watch route 이동시 side-bar, modal close **/
  watch(route, () => {
    const paths = ['/bill', '/bill/view', '/bank-book']
    router.beforeEach(async to => {
      if (paths.indexOf(to.path) > -1) {
        state.allowPath = false
      } else {
        state.allowPath = true
      }
    })
    if (state.isShowSideBar) {
      fnCloseSideBar()
    }
    if (!proxy.$Util.isEmpty(state.modalAlertComponents)) {
      fnRemoveAllModalAlertComponent()
    }
    if (!proxy.$Util.isEmpty(state.modalComponents)) {
      fnRemoveAllModalComponent()
    }
    // 푸시이동 감지
    const unWatchPaths = ['/', '/main']
    if (unWatchPaths.indexOf(route.path) > -1) {
      if (state.unWatch) state.unWatch()
      proxy.$SignSvc.postMapChangeToken()
    } else {
      state.unWatch = fnPushWatcher()
    }
  })
  const fnPushWatcher = () => {
    return proxy.$watch(
      () => state.pushFullPath,
      val => {
        if (!proxy.$Util.isEmpty(val)) {
          setTimeout(() => {
            router.push(val)
            dispatch('user/updatePushFullPath', {
              pushFullPath: ''
            })
          }, 500)
        }
      },
      {
        immediate: true
      }
    )
  }
  return {
    fnCloseModalAlert,
    fnCloseModal,
    ...toRefs(state)
  }
}

export default {
  name: 'wrap-index',
  components: { HeaderBar, NavBar, Spinner },
  setup() {
    return {
      ...layoutState()
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
  transition: opacity 0.3s;
}
</style>
