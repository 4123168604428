<template>
  <div class="pop_center on" v-if="isShow">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'spinner',
  setup() {
    const { getters } = useStore()
    const state = reactive({
      isLoading: computed(() => getters['layout/getIsLoading']),
      isShow: false,
      timer: ''
    })
    /** watch **/
    watch(
      () => state.isLoading,
      val => {
        if (state.timer) {
          clearTimeout(state.timer)
          state.timer = ''
        }
        if (val) {
          state.isShow = true
        } else {
          state.timer = setTimeout(() => {
            state.isShow = false
          }, 100)
        }
      }
    )
    return { ...toRefs(state) }
  }
}
</script>

<style>
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ff7321;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
