<template>
  <div class="modal_dim on" v-show-modal-alert>
    ><!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal">
        <div class="modal_head">
          <p>마케팅 정보 앱 푸시 동의 안내</p>
        </div>
        <div class="modal_con">
          <div class="modal_list">
            <div class="modal_txt">
              <div class="txt_list">
                <dl>
                  <dt>전송자:</dt>
                  <dd>모두 함께해</dd>
                </dl>
                <dl>
                  <dt>일시:</dt>
                  <dd>
                    {{
                      $dayjs(new Date()).format('YYYY년 MM월 DD일 HH시 DD분')
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt>처리내용:</dt>
                  <dd>수신 {{ layout.contents }} 처리 완료</dd>
                </dl>
              </div>
              <div class="txt_list s_nt">
                <p>본 광고성 알림(PUSH)은 환경설정에서 변경 가능합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_btn">
          <div class="btn_wrap">
            <div class="inline">
              <button class="m_btn" @click="fnCallBack">
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  name: 'modal-alert-push',
  description: '회원가입 마케팅 푸시 동의',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callBackText: {
      type: [String],
      default: '확인'
    },
    callBack: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnCallBack = event => {
      props.callBack(proxy, event)
    }
    return { fnCallBack }
  }
}
</script>

<style scoped></style>
