<template>
  <div class="modal_dim off" v-show-modal-alert>
    ><!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal">
        <main class="main" role="main">
          <div class="page">
            <div class="inner">
              <div class="page_con page_bg_grey">
                <div class="bill_img_box">
                  <img
                    :src="$ConstCode.getImagePath(layout.imagePath, 'origin/')"
                    alt="img"
                    class="bill_img_auto"
                    style="max-width: 100%;"
                  />
                  <button>
                    <img
                      @click="fnCallBack"
                      src="@/assets/static/images/i_x.svg"
                      alt="close"
                      class="close"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  name: 'modal-alert-image',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callBackText: {
      type: [String],
      default: '확인'
    },
    callBack: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnCallBack = event => {
      props.callBack(proxy, event)
    }
    return { fnCallBack }
  }
}
</script>

<style scoped></style>
