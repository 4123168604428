import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class VoteSvc
 */
class VoteSvc extends ServiceExec {
  /**
   *  찬반투표 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param page {integer} 1
   */
  fetchUserVoteList(params = {}) {
    return this.get(`/api/vote/fetchUserVoteList`, params).then(response => {
      return response.data
    })
  }

  /**
   *  찬반투표 상세
   *  @param Authorization {string} Authorization
   *  @param voteNo {integer} -1
   */
  fetchUserVote(params = {}) {
    return this.get(`/api/vote/fetchUserVote`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원 찬반 투표
   *  @param Authorization {string} Authorization
   *  @param voteNo {integer} -1
   *  @param voteType {integer} 투표타입 (1: 찬성, 2: 반대)
   */
  updateUserVote(params = {}) {
    return this.get(`/api/vote/updateUserVote`, params).then(response => {
      return response.data
    })
  }
}
export default new VoteSvc()
