<template>
  <div class="modal_dim on" v-show-modal-alert>
    ><!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal">
        <div class="modal_head">
          <p>{{ layout.title }}</p>
        </div>
        <div class="modal_con">
          <div class="modal_list">
            <div class="modal_txt">
              <div class="txt_list">
                <dl>
                  <dt v-html="layout.contents"></dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_btn">
          <div class="btn_wrap">
            <div class="inline">
              <button class="m_btn" @click="fnCallBack">
                {{ callBackText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  name: 'modal-alert-base',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callBackText: {
      type: [String],
      default: '확인'
    },
    callBack: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnCallBack = event => {
      props.callBack(proxy, event)
    }
    return { fnCallBack }
  }
}
</script>

<style scoped></style>
