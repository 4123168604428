import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class ResNoticeSvc
 */
class ResNoticeSvc extends ServiceExec {
  /**
   *  회원 입주민 알림사항 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param page {integer} 1
   */
  fetchUserResNoticeList(params = {}) {
    return this.get(`/api/res/notice/fetchUserResNoticeList`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  입주민 알림사항 상세
   *  @param resNo {integer} -1
   */
  fetchResNotice(params = {}) {
    return this.get(`/api/res/notice/fetchResNotice`, params).then(response => {
      return response.data
    })
  }

  /**
   *  입주민 알림사항 등록
   *  @param Authorization {string} Authorization
   *  @param params {} params
   */
  upsertResNotice(params = {}) {
    return this.post(`/api/res/notice/upsertResNotice`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  입주민 알림사항 삭제
   *  @param resNo {integer} -1
   */
  deleteResNotice(params = {}) {
    return this.delete(`/api/res/notice/deleteResNotice`, params).then(
      response => {
        return response.data
      }
    )
  }
}
export default new ResNoticeSvc()
