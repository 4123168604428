<template>
  <div class="modal_dim on" v-show-modal-alert>
    ><!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal">
        <div class="modal_head">
          <p>업데이트</p>
        </div>
        <div class="modal_con">
          <div class="modal_list">
            <div class="modal_txt">
              <div class="txt_list">
                <dl>
                  <dt>
                    업데이트가 필요합니다.<br />마켓으로 이동 버튼 터치 시
                    업데이트 받을 수 있습니다.
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="modal_btn">
          <div class="btn_wrap">
            <div class="inline">
              <button class="m_btn" @click="fnCallBack">
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  name: 'modal-alert-update',
  description: '업데이트 알림',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callBackText: {
      type: [String],
      default: '확인'
    },
    callBack: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnCallBack = event => {
      props.callBack(proxy, event)
    }
    return { fnCallBack }
  }
}
</script>

<style scoped></style>
