import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class BoardSvc
 */
class BoardSvc extends ServiceExec {
  /**
   *  회원 게시판 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param page {integer} 1
   *  @param type {integer} 게시판 타입 (1: 공지사항, 2: 자주묻는질문)
   */
  fetchUserBoardList(params = {}) {
    return this.get(`/api/board/fetchUserBoardList`, params).then(response => {
      return response.data
    })
  }

  /**
   *  게시판 상세
   *  @param boardNo {integer} -1
   */
  fetchBoard(params = {}) {
    return this.get(`/api/board/fetchBoard`, params).then(response => {
      return response.data
    })
  }
}
export default new BoardSvc()
