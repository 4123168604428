import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class ComplaintSvc
 */
class ComplaintSvc extends ServiceExec {
  /**
   *  회원 민원접수 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param page {integer} 1
   */
  fetchUserComplaintList(params = {}) {
    return this.get(`/api/complaint/fetchUserComplaintList`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  회원 민원접수 등록
   *  @param params {} params
   */
  upsertUserComplaint(params = {}) {
    return this.post(`/api/complaint/upsertUserComplaint`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  민원접수 상세
   *  @param compNo {integer} -1
   */
  fetchComplaint(params = {}) {
    return this.get(`/api/complaint/fetchComplaint`, params).then(response => {
      return response.data
    })
  }

  /**
   *  민원접수 삭제
   *  @param compNo {integer} -1
   */
  deleteComplaint(params = {}) {
    return this.delete(`/api/complaint/deleteComplaint`, params).then(
      response => {
        return response.data
      }
    )
  }
}
export default new ComplaintSvc()
