<template>
  <header class="header">
    <div class="inner type02" v-if="routeLayout.isHeader">
      <!--'메인헤더: type02 추가'-->
      <h1 class="logo">
        <a
          href=""
          @click="$router.push({ path: '/main', query: { isRoot: 1 } })"
        >
          <img src="@/assets/static/images/logo.svg" alt="모두 함께해" />
        </a>
      </h1>
    </div>
    <div class="inner" v-if="!routeLayout.isHeader && routeLayout.isBack">
      <div class="left">
        <button class="h_btn back" @click="$router.go(-1)"></button>
      </div>
      <h1 class="h1">
        <span>{{ routeLayout.titleName }}</span>
      </h1>
    </div>
  </header>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

const layoutState = () => {
  const { getters } = useStore()
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  return {
    routeLayout
  }
}

export default {
  name: 'header-bar-index',
  setup() {
    return {
      ...layoutState()
    }
  }
}
</script>

<style scoped></style>
