import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class BillSvc
 */
class BillSvc extends ServiceExec {
  /**
   *  고지서 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param page {integer} 1
   */
  fetchUserBillList(params = {}) {
    return this.get(`/api/bill/fetchUserBillList`, params).then(response => {
      return response.data
    })
  }
  /**
   *  회원 고지서 상세
   *  @param Authorization {string} Authorization
   *  @param billNo {integer} -1
   */
  fetchUserBill(params = {}) {
    return this.get(`/api/bill/fetchUserBill`, params).then(response => {
      return response.data
    })
  }

  /**
   *  이번달 회원 납부 금액
   *  @param Authorization {string} Authorization
   */
  fetchUserPayment(params = {}) {
    return this.get(`/api/bill/fetchUserPayment`, params).then(response => {
      return response.data
    })
  }
}
export default new BillSvc()
