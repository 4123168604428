import Env from '@/common/constants/Env.json'
import EnvStage from '@/common/constants/Env_stage.json'
import EnvProd from '@/common/constants/Env_prod.json'
import Util from '@/common/utils/Util'

let CODE_ENV_LIST = Env

if (process.env.NODE_ENV === 'production') {
  CODE_ENV_LIST = EnvProd
} else if (process.env.VUE_APP_MODE === 'stage') {
  CODE_ENV_LIST = EnvStage
}

const BOARD_TYPE = {
  NOTICE: { name: '공지사항', value: 1 },
  FAQ: { name: '자주 묻는 질문', value: 2 }
}

const BUILDING_STATUS = {
  COMPANY: { name: '업체 정보', value: 0 },
  PARKING: { name: '주차 현황 표', value: 1 },
  NOTICE: { name: '행동 강령, 입주민 안내문', value: 2 },
  MANAGE: { name: '관리비 산출 내역', value: 3 }
}

const CODE_BANK_NAMES = {
  1: '신한',
  2: '농협',
  3: '기업',
  4: '우리'
}

const BILL_TYPE = {
  BILL: { name: '납입 통지서', value: 0 },
  FEE: { name: '전월 관리비 사용', value: 1 },
  REPORT: { name: '관리보고', value: 2 }
}

const ConstCode = function() {
  return {
    CODE_ENV_LIST,
    BOARD_TYPE,
    BUILDING_STATUS,
    CODE_BANK_NAMES,
    BILL_TYPE,
    // getImagePath(path = '', size = '/480', isFile = false) {
    //   if (isFile) {
    //     return ''
    //   }
    //   size = size.replace('/', '')
    //   return path ? `${this.CODE_ENV_LIST.FILE_SERVER_URL}${path}` : ''
    // },
    getImagePath: function(path = '', size = '') {
      if (Util.isEmpty(path)) return ''
      return `${this.CODE_ENV_LIST.FILE_SERVER_URL}${size}${path}`
    },
    getFilePath: function(path = '') {
      if (Util.isEmpty(path)) return ''
      return `${this.CODE_ENV_LIST.FILE_SERVER_URL}${path}`
    }
  }
}

export default ConstCode()
