import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Util from '@/common/utils/Util'
// import CONFIRM from '@/components/common/modal/alert/confirm'

const updateRouteLayout = async meta => {
  await store.dispatch('layout/updateRouteLayout', {
    isHeader: meta.isHeader,
    titleName: meta.titleName,
    isBack: meta.isBack,
    isNav: meta.isNav
  })
}

const routes = [
  {
    path: '/',
    beforeEnter: function(to, from, next) {
      if (Util.isApp()) {
        // 푸시정보 업데이트
        Util.nativeGetRegistrationKey('nativeSetDeviceInfo')
        next()
      } else {
        next({ path: '/intro', query: { isRoot: 1 } })
      }
    }
  },
  {
    path: '/intro',
    name: 'IntroIndex',
    component: () => import('@/views/intro/index.vue'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: false,
      isNav: false
    }
  },
  {
    path: '/auth',
    name: 'AuthAccess',
    component: () => import('@/views/auth/index.vue'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: false,
      isNav: false
    }
  },
  {
    path: '/main',
    name: 'MainIndex',
    component: () => import('@/views/main/index.vue'),
    meta: {
      isHeader: true,
      titleName: '',
      isBack: false,
      isNav: true
    }
  },
  {
    path: '/sign/in',
    name: 'SignInIndex',
    component: () => import('@/views/sign/in'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: false,
      isNav: false
    },
    props: true
  },
  {
    path: '/sign/up',
    name: 'SignUpIndex',
    component: () => import('@/views/sign/up/index.vue'),
    meta: {
      isHeader: false,
      titleName: '회원가입',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/find',
    name: 'FindIndex',
    component: () => import('@/views/find/index.vue'),
    meta: {
      isHeader: false,
      titleName: '아이디 찾기',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/find/reset',
    name: 'FindReset',
    component: () => import('@/views/find/reset'),
    meta: {
      isHeader: false,
      titleName: '비밀번호 찾기',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/bill',
    name: 'BillIndex',
    component: () => import('@/views/bill'),
    meta: {
      isHeader: false,
      titleName: '고지서 확인',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/bill/view',
    name: 'BillView',
    component: () => import('@/views/bill/view'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: false,
      isNav: false
    },
    props: true
  },
  {
    path: '/bank-book',
    name: 'BankBookIndex',
    component: () => import('@/views/bank-book'),
    meta: {
      isHeader: false,
      titleName: '통장 이력',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/menu',
    name: 'MenuIndex',
    component: () => import('@/views/menu'),
    meta: {
      isHeader: false,
      titleName: '메뉴',
      isBack: true,
      isNav: false
    },
    props: true
  },
  {
    path: '/menu/building/register',
    name: 'BuildingRegisterIndex',
    component: () => import('@/views/menu/building/register'),
    meta: {
      isHeader: false,
      titleName: '빌라 정보 등록',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/complaint',
    name: 'ComplaintIndex',
    component: () => import('@/views/menu/complaint'),
    meta: {
      isHeader: false,
      titleName: '민원 접수',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/complaint/write',
    name: 'ComplaintWrite',
    component: () => import('@/views/menu/complaint/write'),
    meta: {
      isHeader: false,
      titleName: '민원 등록',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/complaint/view',
    name: 'ComplaintView',
    component: () => import('@/views/menu/complaint/view'),
    meta: {
      isHeader: false,
      titleName: '민원 접수',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/vote',
    name: 'VoteIndex',
    component: () => import('@/views/menu/vote'),
    meta: {
      isHeader: false,
      titleName: '찬반 투표',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/vote/view',
    name: 'VoteView',
    component: () => import('@/views/menu/vote/view'),
    meta: {
      isHeader: false,
      titleName: '투표 상세보기',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/board/notice',
    name: 'NoticeIndex',
    component: () => import('@/views/menu/board/notice'),
    meta: {
      isHeader: false,
      titleName: '공지사항',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/board/view',
    name: 'BoardView',
    component: () => import('@/views/menu/board/view'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/notice/tenant',
    name: 'NoticeTenantIndex',
    component: () => import('@/views/menu/notice/tenant'),
    meta: {
      isHeader: false,
      titleName: '입주민 소통 공간',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/notice/tenant/write',
    name: 'NoticeTenantWrite',
    component: () => import('@/views/menu/notice/tenant/write'),
    meta: {
      isHeader: false,
      titleName: '입주민 소통 공간 작성',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/notice/tenant/view',
    name: 'NoticeTenantView',
    component: () => import('@/views/menu/notice/tenant/view'),
    meta: {
      isHeader: false,
      titleName: '입주민 소통 공간 상세보기',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/building/status',
    name: 'BuildingStatusIndex',
    component: () => import('@/views/menu/building/status'),
    meta: {
      isHeader: false,
      titleName: '빌라 현황',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/building/status/view',
    name: 'BuildingStatusView',
    component: () => import('@/views/menu/building/status/view'),
    meta: {
      isHeader: false,
      titleName: '빌라 현황',
      description: '빌라 현황 상세페이지',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/board/faq',
    name: 'FAQIndex',
    component: () => import('@/views/menu/board/faq'),
    meta: {
      isHeader: false,
      titleName: '자주 묻는 질문',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/setting',
    name: 'SettingIndex',
    component: () => import('@/views/menu/setting'),
    meta: {
      isHeader: false,
      titleName: '설정',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/setting/terms',
    name: 'SettingTerms',
    component: () => import('@/views/menu/setting/terms'),
    meta: {
      isHeader: false,
      titleName: '이용약관',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/menu/setting/privacy',
    name: 'SettingPrivacy',
    component: () => import('@/views/menu/setting/privacy'),
    meta: {
      isHeader: false,
      titleName: '개인정보처리방침',
      isBack: true,
      isNav: false
    }
  },
  {
    path: '/test/index',
    name: 'TestIndex',
    component: () => import('@/views/test/index'),
    meta: {
      isHeader: false,
      titleName: '',
      isBack: false,
      isNav: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     if (savedPosition) {
    //       resolve(savedPosition)
    //     } else {
    //       resolve({ left: 0, top: 0 })
    //     }
    //   }, 500)
    // })
  }
})

window.routeQuery = ''

router.beforeEach(async (to, from, next) => {
  let meta = to.meta
  const viewport = document.getElementById('viewport')
  const zoomPages = ['/menu/building/status/view', '/bill/view']
  if (zoomPages.indexOf(to.path) > -1) {
    viewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes'
    )
  } else {
    viewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=12.0, minimum-scale=.25, user-scalable=no'
    )
  }
  const signInPages = ['/menu', '/main']
  if (signInPages.indexOf(to.path) > -1) {
    const userData = store.getters['user/getData']
    if (Util.isEmpty(userData)) {
      await router.push('/sign/in')
    }
  }
  await updateRouteLayout(meta)
  window.routeQuery = to.query
  next()
})

export default router
