/** 모달 ALERT **/
import BASE from '@/components/common/modal/alert/base/index'
import CONFIRM from '@/components/common/modal/alert/confirm/index'
import PUSH from '@/components/common/modal/alert/push/index'
import IMAGE from '@/components/common/modal/alert/image/index'

/** 전체화면 **/
import TERMS from '@/components/common/modal/terms/index'
import PRIVACY from '@/components/common/modal/terms/privacy'

export const modalAlertNames = {
  BASE,
  CONFIRM,
  PUSH,
  IMAGE
}

export const modalNames = {
  TERMS,
  PRIVACY
}
