import ServiceExec from '@/common/utils/ServiceExec'
import Util from '@/common/utils/Util'
import store from '@/store'

/**
 * 함께가는부동산 관련 API Service
 * @class SignSvc
 */
class SignSvc extends ServiceExec {
  /**
   *  유저 회원가입 (토큰, 유저정보 리턴)
   *  @param isMarketing {integer} 마케팅 동의여부 (1: 동의, 0: 미동의)
   *  @param userId {string} 아이디
   *  @param userNo {integer} userNo
   *  @param userPhone {string} 핸드폰번호
   *  @param userPw {string} 비밀번호
   */
  join(params = {}) {
    return this.get(`/api/user/join`, params).then(response => {
      // let authToken = response.headers['authorization']
      // let userData = response.data.entity
      // if (!Util.isEmpty(authToken))
      //   store.dispatch('user/signIn', {
      //     accessToken: authToken,
      //     data: userData
      //   })
      return response.data
    })
  }

  /**
   *  회원 로그인
   *  @param userId {string} 아이디
   *  @param userPw {string} 패스워드
   */
  login(params = {}) {
    return this.get(`/api/user/login`, params).then(response => {
      if (response.data.code !== 1) {
        return response.data
      }
      let authToken = response.headers['authorization']
      let userData = response.data.entity
      if (!Util.isEmpty(authToken))
        store.dispatch('user/signIn', {
          accessToken: authToken,
          data: userData
        })
      return response.data
    })
  }

  /**
   *  회원 토큰 변경
   *  @param Authorization {string} Authorization
   */
  postMapChangeToken(params = {}) {
    return this.post(`/api/user/postMapChangeToken`, params).then(response => {
      if (response.data.code !== 1) {
        return response.data
      }
      let authToken = response.headers['authorization']
      let userData = response.data.entity
      if (!Util.isEmpty(authToken))
        store.dispatch('user/signIn', {
          accessToken: authToken,
          data: userData
        })
      return response.data
    })
  }

  /**
   *  회원 로그아웃
   *  @param Authorization {string} Authorization
   */
  logout(params = {}) {
    return this.get(`/api/user/logout`, params).then(response => {
      store.dispatch('user/signOut')
      return response.data
    })
  }

  /**
   *  아이디/비밀번호 찾기
   *  @param type {integer} type
   *  @param userId {string} userId
   *  @param userPhone {string} userPhone
   */
  findUser(params = {}) {
    return this.get(`/api/user/findUser`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원 비밀번호 변경
   *  @param newPassword {string} 새로운 패스워드
   *  @param userNo {integer} userNo
   */
  updatePassword(params = {}) {
    return this.queryPost(`/api/user/updatePassword`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원탈퇴
   *  @param Authorization {string} Authorization
   *  @param userNo {integer} -1
   */
  deleteUser(params = {}) {
    return this.delete(`/api/user/deleteUser`, params).then(response => {
      return response.data
    })
  }

  /**
   *  인증번호 발송
   *  @param phone {string} 인증번호 받을 휴대폰번호
   *  @param type {string} 인증번호생성타입 ('join' : 회원가입시)
   *  @param userId {string} userId
   */
  send(params = {}) {
    return this.get(`/api/auth/send`, params).then(response => {
      return response.data
    })
  }

  /**
   *  인증번호 확인
   *  @param authCode {string} 인증번호
   *  @param phone {string} 휴대폰번호
   */
  match(params = {}) {
    return this.get(`/api/auth/match`, params).then(response => {
      return response.data
    })
  }
}
export default new SignSvc()
