import Util from '@/common/utils/Util'
import UserSvc from '@/common/service/UserSvc'
// import router from '@/router'

export const state = {
  accessToken: null,
  data: null,
  pushFullPath: '',
  deviceInfo: null
}
export const getters = {
  getAccessToken(state) {
    state.accessToken = state.accessToken || localStorage.accessToken
    return state.accessToken
  },
  getData(state) {
    state.data = localStorage.data || '{}'
    return JSON.parse(state.data)
  },
  getPushFullPath(state) {
    return state.pushFullPath
  },
  getDeviceInfo(state) {
    state.deviceInfo = localStorage.deviceInfo || '{}'
    return JSON.parse(state.deviceInfo)
  }
}
export const mutations = {
  setAccessToken(state, { accessToken = '' }) {
    state.accessToken = accessToken
    localStorage.accessToken = accessToken
  },
  setData(state, { data = {} }) {
    state.data = data
    localStorage.data = JSON.stringify(data)
  },
  storeLogout(state) {
    state.data = null
    state.accessToken = null
    delete localStorage.deviceInfo
    delete localStorage.accessToken
    delete localStorage.data
  },
  setPushFullPath(state, { pushFullPath = '' }) {
    state.pushFullPath = pushFullPath
  }
}
export const actions = {
  async signIn({ commit }, payload) {
    commit('setAccessToken', payload)
    commit('setData', payload)
  },
  async signOut({ commit, getters }) {
    const deviceInfo = getters['getDeviceInfo']
    if (Util.isApp()) {
      await UserSvc.deleteUserDevice(deviceInfo)
    }
    location.replace('/')
    // await router.push({ path: '/sign/in', query: { isRoot: 1 } })
    commit('storeLogout')
  },
  forceSignOut({ commit }) {
    commit('storeLogout')
    location.reload()
  },
  updatePushFullPath({ commit }, { pushFullPath = '' }) {
    commit('setPushFullPath', { pushFullPath })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
