import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class VoteSvc
 */
class BuildingSvc extends ServiceExec {
  /**
   *  빌라 리스트 (이름순)
   */
  fetchBuildingListByName(params = {}) {
    return this.get(`/api/building/fetchBuildingListByName`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  빌라 상세
   *  @param buildingNo {integer} -1
   */
  fetchBuilding(params = {}) {
    return this.get(`/api/building/fetchBuilding`, params).then(response => {
      return response.data
    })
  }

  /**
   *  빌라 리스트 (호수명순)
   */
  fetchBuildingListByNo(params = {}) {
    return this.get(`/api/building/fetchBuildingListByNo`, params).then(
      response => {
        return response.data
      }
    )
  }
}
export default new BuildingSvc()
