export const state = {
  screenOffTime: ''
}
export const getters = {
  getScreenOffTime(state) {
    return state.screenOffTime
  }
}
export const mutations = {
  setScreenOffTime(state, { screenOffTime = '' }) {
    state.screenOffTime = screenOffTime
  }
}
export const actions = {
  async updateScreenOffTime({ commit }, { screenOffTime = '' }) {
    commit('setScreenOffTime', { screenOffTime })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
