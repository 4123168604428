import Util from '@/common/utils/Util'
import CONFIRM from '@/components/common/modal/alert/confirm/index'
import router from '@/router'

const routeState = {
  isHeader: false,
  titleName: '',
  isBack: false,
  isNav: false
}

export const state = {
  isLoading: false,
  // isShowSideBar: false,
  modalAlertComponents: [],
  modalComponents: [],
  routeLayout: {
    ...routeState
  }
}
export const getters = {
  getIsLoading: state => {
    return state.isLoading
  },
  // getIsShowSideBar: state => {
  //   return state.isShowSideBar
  // },
  getModalAlertComponents: state => {
    return state.modalAlertComponents
  },
  getModalComponents: state => {
    return state.modalComponents
  },
  getRouteLayout: state => {
    return state.routeLayout
  }
}
export const mutations = {
  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading
  },
  setIsShowSideBar(state, { isShowSideBar }) {
    state.isShowSideBar = isShowSideBar
  },
  setModalAlertComponents(state, { modalAlertComponents }) {
    state.modalAlertComponents = modalAlertComponents
  },
  setModalComponents(state, { modalComponents }) {
    state.modalComponents = modalComponents
  },
  setRouteLayout(
    state,
    {
      isHeader = undefined,
      titleName = undefined,
      isBack = undefined,
      isNav = undefined
    }
  ) {
    if (isHeader !== undefined) state.routeLayout.isHeader = isHeader
    if (titleName !== undefined) state.routeLayout.titleName = titleName
    if (isBack !== undefined) state.routeLayout.isBack = isBack
    if (isNav !== undefined) state.routeLayout.isNav = isNav
  }
}
export const actions = {
  updateIsLoading({ commit }, { isLoading = false }) {
    commit('setIsLoading', { isLoading })
  },
  updateIsShowSideBar({ commit }, payload) {
    commit('setIsShowSideBar', payload)
  },
  pushModalAlertComponent({ state, commit }, payload) {
    state.modalAlertComponents.push(payload)
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    })
  },
  removeAllModalAlertComponent({ commit }) {
    commit('setModalAlertComponents', {
      modalAlertComponents: []
    })
  },
  closeModalAlertComponent({ state, commit }, { index = 0 }) {
    state.modalAlertComponents.splice(index, 1)
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    })
  },
  async pushModalComponent({ state, commit, dispatch, rootState }, payload) {
    const accessToken = rootState.user.accessToken
    const signInModalComponents = [
      // MATCHING.name,
      // SIMPLE_ANLYS.name,
      // EXPERT_CODE.name,
      // SHARE_REQUEST.name
    ]
    if (Util.isEmpty(accessToken)) {
      if (signInModalComponents.indexOf(payload.component.name) > -1) {
        const payload1 = {}
        payload1.component = CONFIRM
        payload1.layout = {
          title: '알림',
          contents:
            '회원가입 후 서비스 이용이 가능합니다.<br/>회원가입 화면으로 이동하시겠습니까?'
        }
        payload1.data = {}
        payload1.callBackDone = () => {
          router.push({ path: '/' })
        }
        payload1.callBackCancel = proxy => {
          proxy.$emit('close')
        }
        await dispatch('pushModalAlertComponent', payload1)
        return
      }
    }
    state.modalComponents.push(payload)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  removeAllModalComponent({ commit }) {
    commit('setModalComponents', {
      modalComponents: []
    })
  },
  closeModalComponent({ state, commit }, { index = 0 }) {
    state.modalComponents.splice(index, 1)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  updateRouteLayout({ commit }, payload) {
    commit('setRouteLayout', payload)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
