<template>
  <nav class="bottom">
    <div class="bottom_wrap">
      <div class="btm_icon on" @click="fnPage(0)">
        <div class="img home"></div>
        <p>홈</p>
      </div>
      <div class="btm_icon" @click="fnPage(1)">
        <div class="img bill"></div>
        <p>고지서 확인</p>
      </div>
      <div class="btm_icon" @click="fnPage(2)">
        <div class="img bank"></div>
        <p>통장확인</p>
      </div>
      <div class="btm_icon" @click="fnPage(3)">
        <div class="img menu"></div>
        <p>메뉴</p>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'nav-bar',
  description: '하단 네비게이션',
  setup() {
    const router = useRouter()
    const fnPage = (index = 0) => {
      /*
      01. 홈
      01. 고지서 확인
      02. 통장확인
      03. 메뉴
       */
      const paths = ['/main', '/bill', '/bank-book', '/menu']
      if (index === 0) {
        router.push({ path: '/main', query: { isRoot: 1 } })
      } else {
        router.push({ path: paths[index] })
      }
    }

    return { fnPage }
  }
}
</script>

<style scoped></style>
