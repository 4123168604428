import ServiceExec from '@/common/utils/ServiceExec'

/**
 * 함께가는부동산 관련 API Service
 * @class UserSvc
 */
class UserSvc extends ServiceExec {
  /**
   *  회원 디바이스 정보 등록
   *  @param userDeviceBean {UserDeviceBean} userDeviceBean
   */
  insertUserDevice(params = {}) {
    return this.queryPost(`/api/user/insertUserDevice`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  회원 디바이스 정보 삭제
   *  @param deviceId {string} deviceId
   */
  deleteUserDevice(params = {}) {
    return this.delete(`/api/user/deleteUserDevice`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원 빌라 정보 등록
   *  @param Authorization {string} Authorization
   *  @param buildingNo {integer} -1
   *  @param owner {string}
   *  @param unit {string}
   *  @param userNo {integer} -1
   */
  updateUserBuildingInfo(params = {}) {
    return this.queryPost(`/api/user/updateUserBuildingInfo`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  회원 빌라 등록정보 상세보기
   *  @param userNo {integer} -1
   */
  fetchUserRequestData(params = {}) {
    return this.get(`/api/user/fetchUserRequestData`, params).then(response => {
      return response.data
    })
  }

  /**
   *  회원 마케팅 및 알림 상태 변경
   *  @param Authorization {string} Authorization
   *  @param isMarketing {integer} -1
   *  @param isNotification {integer} isNotification
   */
  updateUserPushStatus(params = {}) {
    return this.queryPost(`/api/user/updateUserPushStatus`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  회원 통장이력 가져오기
   *  @param Authorization {string} Authorization
   *  @param limit {integer} -1
   *  @param month {integer}
   *  @param page {integer} 1
   *  @param year {integer}
   */
  fetchUserBankBook(params = {}) {
    return this.get(`/api/user/fetchUserBankBook`, params).then(response => {
      return response.data
    })
  }
}
export default new UserSvc()
