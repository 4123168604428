import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
/**
 * 함께가는 부동산 관련 API Service
 * @class CommonSvc
 */
class CommonSvc extends ServiceExec {
  /**
   *  파일업로드
   *  @param file {file} file
   */
  uploadFile(params = {}) {
    const formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    return this.multiPost(`/api/common/uploadFile`, formData).then(response => {
      return response.data
    })
  }

  /**
   *  앱 버전 가져오기
   *  @param deviceType {integer} 디바이스타입 (1: android, 2: ios)
   */
  getMapAppVersion(params = {}) {
    return this.get(`/api/common/getMapAppVersion`, params).then(response => {
      return response.data
    })
  }

  /**
   *  디바이스 정보 가져오기
   *  @param deviceId {string}
   */
  getUserDevice(params = {}) {
    return this.get(`/api/common/getUserDevice`, params).then(response => {
      return response.data
    })
  }
}
export default new CommonSvc()
